/* added for A/B test 40c1cd3f-filter-GDPR-users banner hidden by default */
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  min-height: 100vh;
}

/* Splash screen */
#splash_screen {
  display: none;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
#splash_screen[data-visible='true'] {
  display: flex;
  opacity: 1;
}
@media (min-width: 744px) {
  #splash_screen {
    display: none;
  }
}
#splash_screen > .logo {
  position: absolute;
  width: 50px;
}
#splash_screen > .loader {
  width: 100px;
  height: 100px;
  border: 2px solid #3c69f5;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
